// Breakpoints
$breakpoint: 1024px; // large
$breakpoint-desktop: 992px; // medium
$breakpoint-tablet: 768px; // 768px == iPad
$breakpoint-phone: 374px; // 375px == iPhone 6/7/8

// Colors
$black: #000000;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-dot: #bebebe !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$green: #28a745;
$royal-blue-1: #00448F;
$royal-blue-2: #0057b7;
$royal-blue-3: #0079FF;
$dark-blue-1: #012754;
$dark-blue-2: #02346f;
$dark-blue-3: #044088;
$light-gray: #f4f4f4;
$dark-gray: #7c7c7c;
$purple-1: #403A60;
$purple-2: #9790bc;
$purple-3: #e2e0ec;
$red: #dc3545;
$red-orange-1: #DC582A;
$red-orange-2: #ea9d82;
$red-orange-3: #f5d0c3;
$steel-gray-1: #9EA2A2;
$steel-gray-2: #c5c7c7;
$steel-gray-3: #dfe0e0;
// $teal-1: #0093B2;
$teal-1: #4c92b1;
$teal-2: #7fe9ff;
$teal-3: #cbf6ff;
$teal-light: #77C5D5;
$white: #FFFFFF;
$white-off: #f9f8f7;
$nav-purple: #15112e;
$yellow: #ffc107;

// $status-new: #6c757d;
$status-new: $teal-light;
$status-active: #28a745;
$status-review: #ffc107;
$status-closed: #dc3545;
$status-invited: $teal-light;
$status-working: #dc3545;
$status-completed: #28a745;
$status-rejected: $steel-gray-1;
$status-on-market: $green;
$status-off-market: $red;
$status-off-market-willing-to-sell: $gray-700;

// Custom variables

$dashboard-bg-light: $gray-100;
$dashboard-bg-dark: $black;
$dashboard-bg-header: $white;

$strata-bg-light: $royal-blue-3;
$strata-bg-dark: $royal-blue-1;

$inbox-bg-light: $teal-3;
$inbox-bg-dark: $teal-1;

$projects-bg-light: $purple-3;
$projects-bg-dark: $purple-1;

$templates-bg-light: $steel-gray-3;
$templates-bg-dark: $steel-gray-1;

$reports-bg-light: $red-orange-3;
$reports-bg-dark: $red-orange-1;

$row-striping: $gray-100;

$page-header: #ececec;


/* Override bootstrap theme - only overriding primary and secondary - uncomment other lines
   and define colors to override more theme colors
*/

$primary:       $purple-1 !default;
$secondary:     $red-orange-1 !default;
$success:       $green !default;
// $info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
// $light:         $teal-light !default;
// $dark:          $gray-800 !default;
$strata:        $strata-bg-dark !default;
$inbox:         $inbox-bg-dark !default;
$marketing:     $teal-light !default;
$projects:      $projects-bg-dark !default;
$templates:     $templates-bg-dark !default;
$reports:       $reports-bg-dark !default;
$status-new:    $status-new !default;
$status-active: $status-active !default;
$status-review: $status-review !default;
$status-closed: $status-closed !default;
$status-invited: $status-invited !default;
$status-working: $status-working !default;
$status-completed: $status-completed !default;
$status-rejected: $status-rejected !default;
$status-on-market: $status-on-market !default;
$status-off-market: $status-off-market !default;
$status-off-market-willing-to-sell: $status-off-market-willing-to-sell !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    // "info":       $info,
    // "warning":    $warning,
    "danger":     $danger,
    // "light":      $light,
    // "dark":       $dark,
    "inbox":      $inbox,
    "marketing":  $marketing,
    "projects":   $projects,
    "templates":  $templates,
    "reports":    $reports,
    "status-new": $status-new,
    "status-active": $status-active,
    "status-review": $status-review,
    "status-closed": $status-closed,
    "status-working": $status-working,
    "status-completed": $status-completed,
    "status-rejected": $status-rejected,
    "status-on-market": $status-on-market,
    "status-off-market": $status-off-market,
    "status-off-market-willing-to-sell": $status-off-market-willing-to-sell,
    "strata": $strata,
  ),
  $theme-colors
);

// Bootstrap overrides

// Grid System
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 769px,
//   lg: 993px,
//   xl: 1025px
// );

// $container-max-widths: (
//   sm: 540px,
//   // md: 768px,
//   md: 660px,
//   lg: 875px,
//   xl: 990px
// );

// Fonts

$font-family-sans-serif: figtree, asap, arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
// $headings-font-family: figtree, arial, serif !default;
$headings-font-family: figtree, asap, arial, serif !default;
$headings-font-weight: 700 !default;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.75;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$small-font-size: 70%;

// Forms
$input-border-radius:                   0 !default;

// Links
$link-color: $gray-800;

// $link-decoration: underline;
$link-hover-color: rgba($gray-800, 0.7);
$link-hover-decoration: underline;


// Navs
// $navbar-light-color:                $white-off !default;
// $navbar-light-hover-color:          $red-orange-1 !default;
// $navbar-light-active-color:         $black !default;
$navbar-light-color:                #cccccc !default;
$navbar-light-hover-color:          $white !default;
$navbar-light-active-color:         $white !default;
$nav-link-padding-y:                .25rem !default;
$nav-link-padding-x:                1rem !default;
$navbar-padding-y:                  0.5rem !default;
$navbar-padding-x:                  0 !default;

// $dropdown-link-color:               $white !default;
// $dropdown-link-hover-color:         $red-orange-1 !default;
// $dropdown-link-hover-bg:            transparent !default;
// $dropdown-link-active-color:        $white !default;
// $dropdown-font-size:                $font-size-sm !default;

// Modal
$close-color:                       $white !default;























.page-wrap.full-page {
  // background: $teal-light;
  height: 100vh;
  .page-content {
    align-items: center;
    background-color: $white !important;
    // background: transparent;
    flex-direction: column;
    justify-content: center;
  }
  .logo {
    height: 12rem;
    margin-bottom: 1rem;
    @media(min-width: $breakpoint-tablet) {
      height: 20rem;
    }
  }
}
