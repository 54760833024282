// Breakpoints
$breakpoint: 1024px; // large
$breakpoint-desktop: 992px; // medium
$breakpoint-tablet: 768px; // 768px == iPad
$breakpoint-phone: 374px; // 375px == iPhone 6/7/8

// Colors
$black: #000000;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-dot: #bebebe !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$green: #28a745;
$royal-blue-1: #00448F;
$royal-blue-2: #0057b7;
$royal-blue-3: #0079FF;
$dark-blue-1: #012754;
$dark-blue-2: #02346f;
$dark-blue-3: #044088;
$light-gray: #f4f4f4;
$dark-gray: #7c7c7c;
$purple-1: #403A60;
$purple-2: #9790bc;
$purple-3: #e2e0ec;
$red: #dc3545;
$red-orange-1: #DC582A;
$red-orange-2: #ea9d82;
$red-orange-3: #f5d0c3;
$steel-gray-1: #9EA2A2;
$steel-gray-2: #c5c7c7;
$steel-gray-3: #dfe0e0;
// $teal-1: #0093B2;
$teal-1: #4c92b1;
$teal-2: #7fe9ff;
$teal-3: #cbf6ff;
$teal-light: #77C5D5;
$white: #FFFFFF;
$white-off: #f9f8f7;
$nav-purple: #15112e;
$yellow: #ffc107;

// $status-new: #6c757d;
$status-new: $teal-light;
$status-active: #28a745;
$status-review: #ffc107;
$status-closed: #dc3545;
$status-invited: $teal-light;
$status-working: #dc3545;
$status-completed: #28a745;
$status-rejected: $steel-gray-1;
$status-on-market: $green;
$status-off-market: $red;
$status-off-market-willing-to-sell: $gray-700;

// Custom variables

$dashboard-bg-light: $gray-100;
$dashboard-bg-dark: $black;
$dashboard-bg-header: $white;

$strata-bg-light: $royal-blue-3;
$strata-bg-dark: $royal-blue-1;

$inbox-bg-light: $teal-3;
$inbox-bg-dark: $teal-1;

$projects-bg-light: $purple-3;
$projects-bg-dark: $purple-1;

$templates-bg-light: $steel-gray-3;
$templates-bg-dark: $steel-gray-1;

$reports-bg-light: $red-orange-3;
$reports-bg-dark: $red-orange-1;

$row-striping: $gray-100;

$page-header: #ececec;


/* Override bootstrap theme - only overriding primary and secondary - uncomment other lines
   and define colors to override more theme colors
*/

$primary:       $purple-1 !default;
$secondary:     $red-orange-1 !default;
$success:       $green !default;
// $info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
// $light:         $teal-light !default;
// $dark:          $gray-800 !default;
$strata:        $strata-bg-dark !default;
$inbox:         $inbox-bg-dark !default;
$marketing:     $teal-light !default;
$projects:      $projects-bg-dark !default;
$templates:     $templates-bg-dark !default;
$reports:       $reports-bg-dark !default;
$status-new:    $status-new !default;
$status-active: $status-active !default;
$status-review: $status-review !default;
$status-closed: $status-closed !default;
$status-invited: $status-invited !default;
$status-working: $status-working !default;
$status-completed: $status-completed !default;
$status-rejected: $status-rejected !default;
$status-on-market: $status-on-market !default;
$status-off-market: $status-off-market !default;
$status-off-market-willing-to-sell: $status-off-market-willing-to-sell !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    // "info":       $info,
    // "warning":    $warning,
    "danger":     $danger,
    // "light":      $light,
    // "dark":       $dark,
    "inbox":      $inbox,
    "marketing":  $marketing,
    "projects":   $projects,
    "templates":  $templates,
    "reports":    $reports,
    "status-new": $status-new,
    "status-active": $status-active,
    "status-review": $status-review,
    "status-closed": $status-closed,
    "status-working": $status-working,
    "status-completed": $status-completed,
    "status-rejected": $status-rejected,
    "status-on-market": $status-on-market,
    "status-off-market": $status-off-market,
    "status-off-market-willing-to-sell": $status-off-market-willing-to-sell,
    "strata": $strata,
  ),
  $theme-colors
);

// Bootstrap overrides

// Grid System
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 769px,
//   lg: 993px,
//   xl: 1025px
// );

// $container-max-widths: (
//   sm: 540px,
//   // md: 768px,
//   md: 660px,
//   lg: 875px,
//   xl: 990px
// );

// Fonts

$font-family-sans-serif: figtree, asap, arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
// $headings-font-family: figtree, arial, serif !default;
$headings-font-family: figtree, asap, arial, serif !default;
$headings-font-weight: 700 !default;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.75;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$small-font-size: 70%;

// Forms
$input-border-radius:                   0 !default;

// Links
$link-color: $gray-800;

// $link-decoration: underline;
$link-hover-color: rgba($gray-800, 0.7);
$link-hover-decoration: underline;


// Navs
// $navbar-light-color:                $white-off !default;
// $navbar-light-hover-color:          $red-orange-1 !default;
// $navbar-light-active-color:         $black !default;
$navbar-light-color:                #cccccc !default;
$navbar-light-hover-color:          $white !default;
$navbar-light-active-color:         $white !default;
$nav-link-padding-y:                .25rem !default;
$nav-link-padding-x:                1rem !default;
$navbar-padding-y:                  0.5rem !default;
$navbar-padding-x:                  0 !default;

// $dropdown-link-color:               $white !default;
// $dropdown-link-hover-color:         $red-orange-1 !default;
// $dropdown-link-hover-bg:            transparent !default;
// $dropdown-link-active-color:        $white !default;
// $dropdown-font-size:                $font-size-sm !default;

// Modal
$close-color:                       $white !default;

@import '_variables';
// @import '_forms';

// currently all bootstrap overrides are in variables, but can be moved to this file below
// @import '_bootstrap-vue-customizations';


@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import 'node_modules/bootstrap-vue/src/icons.scss';

@import '_buefy-customization.scss';

// Global Theming
@font-face {
  font-family: figtree;
  src: url('../fonts/Figtree/Figtree-VariableFont_wght.ttf');
}

.row-stripe {
  background: $row-striping;
}

h1.page-heading {
  font-family: 'figtree'
}

.theme-inbox {
  h1, h2, h3, h4, h5 {
    color: $inbox-bg-dark;
  }
  svg {
    path {
      fill: $inbox-bg-dark;
    }
  }
}

.theme-projects {
  h1, h2, h3, h4, h5 {
    color: $projects-bg-dark;
  }
}

.theme-reports {
  h1, h2, h3, h4, h5 {
    // color: $reports-bg-dark;
    color: $projects-bg-dark;
  }
  svg {
    path {
      fill: $reports-bg-dark;
    }
  }
}

.theme-templates {
  h1, h2, h3, h4, h5 {
    // color: $templates-bg-dark;
    color: $projects-bg-dark;
  }
  svg {
    path {
      fill: $templates-bg-dark;
    }
  }
}

.mobile {
  .page-header {
    background: $projects-bg-dark;
    h1 {
      color: $white;
    }
    // background: $dashboard-bg-header;
    // h1, h2, h3, h4, h5 {
    //   color: $black;
    // }
    // svg line {
    //   stroke: $black; 
    // }
  }
  // .theme-inbox {
  //   .page-header {
  //     background: $inbox-bg-dark;
  //     h1 {
  //       color: $white;
  //     }
  //   }
  // }
  // .theme-projects {
  //   .page-header {
  //     background: $projects-bg-dark;
  //     h1 {
  //       color: $white;
  //     }
  //   }
  // }
  // .theme-reports {
  //   .page-header {
  //     background: $reports-bg-dark;
  //     h1 {
  //       color: $white;
  //     }
  //   }
  // }
  // .theme-templates {
  //   .page-header {
  //     background: $templates-bg-dark;
  //     h1 {
  //       color: $white;
  //     }
  //   }
  // }
}

// Custom Page Header (projects, templates, admin)
.page-header-wrapper {
  background: $gray-300;
  margin-bottom: 1rem;
  padding: 1rem 0;
  .page-heading {
    color: $dark-blue-2;
    margin: 0 1rem 0 0;
  }
  .header-actions {
    .row {
      align-items: center;
    }
    .actions-wrapper {
      button:not(.new):not(.btn-search-field),
      .search-input {
        align-items: center;
        display: flex;
        margin-left: 1rem;
        // input {
        //   min-width: 5rem;
        // }
        &.has-icons-right .input:hover ~ .icon {
          color: #4a4a4a;
        }
        .icon:hover {
          color: #4a4a4a;
        }
        &+.control .select select {
          padding-right: 1rem;
        }
      }
      button.new {
        margin-left: 1rem;
      }
      .actions {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        @media (max-width: $breakpoint-desktop) {
          justify-content: center;
          padding: 0 15px 0 0
        }
      }
      .breadcrumb {
        display: flex;
      }
    }
  }
  .header-search {
    .row {
      align-items: center;
      height: 100%;
      .col {
        align-items: center;
        display: flex;
      }
    }
  }
  .refresh-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    .spinner-border {
      height: 23px;
      width: 24px;
    }
    &.small {
      .spinner-border {
        height: 19px;
        width: 19px;
        margin: 4px 3px 2px;
      }
    }
  }
  .refreshing {
    cursor: not-allowed !important;
  }
}

// Buttons
.icon-button {
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  &:hover {
    color: $red-orange-1;
    text-decoration: none;
    svg {
      circle,
      line,
      path,
      rect,
      polyline,
      polygon {
        stroke: $red-orange-1 !important;
      }
    }
  }
}
.btn:focus, .btn.focus {
  box-shadow: none;
  // background-color: #f8f9fa;
  // border-color: $red-orange-1;
  // color: $red-orange-1;
  // svg {
  //   circle,
  //   line,
  //   path,
  //   rect,
  //   polyline,
  //   polygon {
  //     stroke: $red-orange-1 !important;
  //   }
  // }
}
.btn:disabled {
  cursor: not-allowed;
}
.btn.saving {
  cursor: wait !important;
}
// .btn-light:not(:disabled):not(.disabled):active,
// .btn-light:not(:disabled):not(.disabled).active,
// .show > .btn-light.dropdown-toggle {
//   background: initial;
//   border-color: $red-orange-1;
//   color: $red-orange-1;
//   svg {
//     circle,
//     line,
//     path,
//     rect,
//     polyline,
//     polygon {
//       stroke: $red-orange-1 !important;
//     }
//   }
// }

// Resets
img {
  max-width: 100%;
}
.ordered {
  list-style-type: decimal;
}

// Text (Buefy requires import of core styles to get these but if the entire core is imported it had
// many overrides of existing styles, so just redefining the ones I need
.has-text-centered {
  text-align: center;
}
.has-text-danger {
  color: $danger !important;
}
.has-text-warning {
  color: $warning !important;
}
.has-text-success {
  color: $success !important;
}
.has-text-inbox {
  color: $inbox !important;
}
.has-text-templates {
  color: $steel-gray-1 !important;
}
.is-invisible {
  visibility: hidden !important;
}

.required {
  color: #dc3545 !important;
  span {
    display: block;
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }
}
.required-badge,
.requirement-badge {
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
  .badge {
    font-size: 50%;
  }
}


// Bootstrap overrides that didn't have sass variables
.modal-title {
  color: $white;
  font-family: 'asap';
  font-size: 1.25rem;;
}

// Quill resets
.ql-editor,
.message-body {
  h1, h2, h3, h4, h5 {
    color: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  p {
    margin-bottom: 0 !important;
  }
  svg {
    path {
      fill: unset !important;
    }
  }
}

.ql-editor blockquote,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
  padding: 0 !important;
  margin: 0 !important;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
}

// Forms
form {
  h4, h5 {
    margin-bottom: 0.25rem !important;
  }
  .field {
    margin-bottom: 1rem;
  }
  .field.is-grouped-multiline .field {
    margin-bottom: 1rem;
  }
}
.form-actions {
  margin: 1rem 0;
  button {
    margin-right: 1rem;
    min-width: 5rem;
  }
}
// input, select, .taginput-container {
//   border-radius: 0 !important;
// }

// .form-group {
//   padding-top: 1.25rem;
//   position: relative;
//   .form-control {
//     border-radius: 0;
//     &.is-invalid {
//       & + label {
//         color: $danger;
//       }
//     }
//     &.is-invalid,
//     &.is-valid,
//     &:focus {
//       & + label{
//         font-size: 1rem;
//         font-weight: bold;
//         opacity: 1;
//         left: 5px;
//         top: -5px;
//       }
//     }
//   }
//   label {
//     opacity: 0.5;
//     position: absolute;
//     top: 25px;
//     left: 10px;
//     z-index: 1000;
//     transition: all 0.2s;
//   }
// }

// Transitions

.page-enter-active,
.page-leave-active {
  transition: opacity 0.2s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}

// .layout-enter-active,
// .layout-leave-active {
//   transition: opacity 0.2s;
// }
// .layout-enter,
// .layout-leave-to {
//   opacity: 0;
// }


// Misc

.help-icon {
  padding: 0;
  i {
    &:before {
      font-size: 20px !important;
    }
  }
  &:hover {
    i {
      &:before {
        color: $inbox;
      }
    }
  }
}

.list-filters {
  .sort {
    border-bottom: 2px solid transparent;
    border-radius: 0;
    color: $gray-600;
    font-weight: 700;
    padding: 0;
    &:focus, &.focus {
      box-shadow: none;
      outline: none;
      text-decoration: none;
    }
    &.active {
      border: 0;
      border-bottom: 2px solid $gray-600;
    }
    &:hover {
      text-decoration: none;
    }
    &:not(.active):not(.btn-filter):not(.no-icon) {
      &:hover {
        .icon {
          display: flex !important;
          i:before {
            content: '\F0A64'
            // content: '\F054F';
          }
        }
      }
    }
  }
}

.load-more {
  .col {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    button {
      align-items: center;
      display: flex;
      .spinner-border {
        margin: 0 0.5rem;
      }
    }
  }
}

.noninteractive {
  position: relative;
  &:hover {
    cursor: not-allowed;
  }
  &:before {
    background: rgba(255, 255, 255, 0.5);
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000
  }
}

.result-count {
  .col {
    color: $gray-600;
    display: flex;
    font-size: 0.8rem;
    justify-content: flex-end;
  }
}

// Print styling

@media print {
  footer.main-footer,
  header,
  .page-header-wrapper,
  .report-actions,
  .report-buttons {
    display: none
  }
  .report-result-content {
    border: 0;
    padding: 0;
  }
}

// Buefy Steps

.b-steps .steps .step-items:not(.questionnaire_review-questions.ul) {
  list-style-type: none;
}

.b-steps .steps .step-items .step-item .step-link:hover {
  cursor: pointer;
  text-decoration: none;
}
