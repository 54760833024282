// Import Bulma's core utilities
@import "~bulma/sass/utilities/_all";

// Set your colors
// $primary: $purple-1;
// $primary-invert: findColorInvert($primary);
$strata: $royal-blue-2;
$strata-invert: findColorInvert($strata);
$inbox: $teal-1;
$inbox-invert: findColorInvert($inbox);
$info: $teal-3;
$info-invert: findColorInvert($info);
$projects: $purple-1;
$projects-invert: findColorInvert($purple);
$reports: $red-orange-1;
$reports-invert: findColorInvert($red-orange-1);
$templates: $steel-gray-1;
$templates-invert: findColorInvert($steel-gray-1);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "inbox": ($inbox, $inbox-invert),
    "projects": ($projects, $projects-invert),
    "reports": ($reports, $reports-invert),
    "templates": ($templates, $templates-invert),
    "strata": ($strata, $strata-invert)
);

// Links
// $link: $primary;
// $link-invert: $primary-invert;
// $link-focus-border: $primary;

$dropdown-item-hover-color: $red-orange-1;
$dropdown-item-hover-background-color: $gray-300;
$input-color: $gray-700;
$select-arrow-color: $purple-1;
$body-background-color: white;

// Import Bulma and Buefy styles
// @import "~bulma";
// @import "~buefy/src/scss/buefy";
// @import "~bulma/sass/components/dropdown";
// @import "~bulma/sass/elements/_all";
// @import "~bulma/sass/form/_all";
// @import "~bulma/sass/helpers/_all";

@import "~bulma/sass/components/dropdown";
@import "~bulma/sass/elements/_all";
@import "~bulma/sass/form/_all";

// @import "~buefy/src/utils/icons";
@import "~buefy/src/scss/utils/_all.scss";
@import "~buefy/src/scss/components/_autocomplete";
@import "~buefy/src/scss/components/_checkbox";
@import "~buefy/src/scss/components/_dropdown";
@import "~buefy/src/scss/components/_form";
@import "~buefy/src/scss/components/_icon";
@import "~buefy/src/scss/components/_numberinput";
@import "~buefy/src/scss/components/_radio";
@import "~buefy/src/scss/components/_steps";
@import "~buefy/src/scss/components/_switch";
// @import "~buefy/src/scss/components/_table";
@import "~buefy/src/scss/components/_tag";
@import "~buefy/src/scss/components/_taginput";
@import "~buefy/src/scss/components/_upload";

